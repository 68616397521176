import { format } from 'date-fns';
import { FC, useState } from 'react';
import { Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { Link } from 'react-router-dom';

import { Entity } from 'domain/types/Entity';
import { Service } from 'domain/entities/Service';
import { isEntityLocked } from 'domain/entities/BillingStatus';
import { useCaseSearchServices } from 'application/services/useCases/useCaseSearchServices';
import usePermission from 'application/auth/hooks/usePermission';
import { JobResourceType } from 'application/auth/utils/resources';
import { useTranslationPrefix } from 'infrastructure/translations/i18n';
import { IconButton, Loader, Table, TableColumn } from 'targets/web/components';
import ReactRouterLink from 'targets/web/components/ReactRouterLink';
import {
  ServiceBillingStatusChip,
  ServiceStatusChip,
} from 'targets/web/modules/jobs/components/StatusChips';
import { useJobViewFilters } from 'targets/web/modules/jobs/hooks/useJobViewFilters';
import { Timer } from 'targets/web/modules/jobs/components/Timer';
import { utcToLocalTime } from 'targets/web/modules/jobs/utils';

const TimeTrackCell: FC<{ service: Entity<Service>; refresh: () => void }> = ({
  service,
  refresh,
}) => {
  const isAvailable = !isEntityLocked(service) && !isEntityLocked(service.job);

  const { isLoading, granted } = usePermission(
    isAvailable
      ? {
          resource: `${JobResourceType}:${service.jobId}`,
          scope: 'record-time',
        }
      : undefined,
  );

  if (!isAvailable) {
    return null;
  }

  if (isLoading) {
    return <Loader size={16} />;
  }

  return granted && isAvailable && <Timer service={service} refresh={refresh} />;
};

const ActionsCell: FC<{ service: Entity<Service> }> = ({ service }) => {
  const { granted, isLoading } = usePermission({
    resource: `${JobResourceType}:${service.jobId}`,
    scope: 'update',
  });

  if (isLoading) {
    return <Loader size={16} />;
  }

  if (!granted) {
    return null;
  }

  return (
    <IconButton
      size="small"
      variant="text"
      color="primary"
      disabled={isEntityLocked(service) || isEntityLocked(service.job)}
      component={Link}
      // TODO: Link to edit service
      // @ts-expect-error TS2322
      to={`/jobs/${service.jobId}/services/${service.id}/edit`}
    >
      <EditIcon />
    </IconButton>
  );
};

const ServicesTable: FC = () => {
  const t = useTranslationPrefix('jobs.services_view.table');
  const [currentPage, setCurrentPage] = useState(0);
  const { serviceFilters, filters, setFilters, setSort } = useJobViewFilters();
  const { total, pages, fetchNextPage, isFetching, isLoading, refetch } = useCaseSearchServices({
    ...serviceFilters,
    stationIds: serviceFilters.stationIds,
  });

  const columns: TableColumn<Entity<Service>>[] = [
    {
      headerName: t('columns.service_name'),
      field: 'serviceType',
      sortable: true,
      renderCell: ({ serviceType, id, jobId }) => (
        <ReactRouterLink
          data-testname={`service-id-${id}`}
          to={`/jobs/${jobId}/services/${id}/details`}
          variant="body1"
          color="primary"
        >
          {serviceType.name}
        </ReactRouterLink>
      ),
    },
    {
      headerName: t('columns.status'),
      renderCell: ({ id, jobId, status, billingStatus, job, scheduledDue, code }) => (
        <ServiceStatusChip
          id={id}
          jobId={jobId}
          status={status}
          code={code}
          billingStatus={billingStatus}
          jobBillingStatus={job.billingStatus}
          scheduledDue={scheduledDue}
          availableStatusChanges={['in_progress', 'pending']}
        />
      ),
    },
    {
      headerName: t('columns.billing_status'),
      field: 'status',
      renderCell: ({ id, jobId, billingStatus, status, job }) => (
        <ServiceBillingStatusChip
          id={id}
          jobId={jobId}
          status={status}
          billingStatus={billingStatus}
          jobBillingStatus={job.billingStatus}
        />
      ),
    },
    {
      headerName: t('columns.scheduled'),
      field: 'scheduledStart',
      sortable: true,
      renderCell: ({ scheduledStart }) => (
        <Typography noWrap>
          {scheduledStart ? format(utcToLocalTime(scheduledStart), 'MMM dd, yyyy') : 'N/A'}
        </Typography>
      ),
    },
    {
      headerName: t('columns.due_date'),
      field: 'scheduledDue',
      sortable: true,
      renderCell: ({ scheduledDue }) => (
        <Typography noWrap>{format(utcToLocalTime(scheduledDue), 'MMM dd, yyyy')}</Typography>
      ),
    },
    {
      headerName: t('columns.job'),
      field: 'job',
      sortable: true,
      renderCell: ({ job }) => <Typography noWrap>{job.name}</Typography>,
    },
    {
      headerName: t('columns.time_tracker'),
      renderCell: (service) => <TimeTrackCell service={service} refresh={refetch} />,
    },
    {
      type: 'actions',
      width: '5%',
      renderCell: (service) => <ActionsCell service={service} />,
    },
  ];

  return (
    <Table
      columns={columns}
      rows={pages[currentPage]?.items ?? []}
      rowsPerPageOptions={[10, 50, 100]}
      initialRowsPerPage={filters?.limit ?? 10}
      count={total}
      onRowsPerPageChange={(rowsPerPage) => {
        setFilters((current) => ({ ...current, limit: rowsPerPage }));
        setCurrentPage(0);
      }}
      onPageChange={async (pageNumber) => {
        if (!pages[pageNumber]) {
          await fetchNextPage();
        }

        setCurrentPage(pageNumber);
      }}
      onSortChange={(sortBy, direction) => {
        setSort({
          sortBy: sortBy === 'serviceType' ? 'serviceType.name' : sortBy,
          direction,
        });
        setCurrentPage(0);
      }}
      initialPage={currentPage}
      isLoading={isLoading || isFetching}
    />
  );
};

export default ServicesTable;
