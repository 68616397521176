import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import { IconButton, Stack } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import { Location, useLocation } from 'react-router-dom';

import { CreateJobData } from 'domain/repositories/IJobRepository';
import { useCaseCreateJob } from 'application/jobs/useCases/useCaseCreateJob';
import { useCaseCheckAccess } from 'application/auth/useCases/useCaseCheckAccess';
import { useTranslationPrefix } from 'infrastructure/translations/i18n';
import { jobsRoutes } from 'targets/web/modules/jobs/navigation/jobsRoutes';
import { JobDetailsData, JobDetailsForm } from 'targets/web/modules/jobs/components';
import {
  FormikCancelSaveFooter,
  PageTitle,
  PersistFormikValues,
} from 'targets/web/modules/dashboard/components';
import { Button } from 'targets/web/components/Button';
import Breadcrumbs, { Breadcrumb } from 'targets/web/components/Breadcrumbs';
import { ConfirmationModal } from 'targets/web/components/ConfirmationModal';
import { usePersistedValues } from 'targets/web/modules/dashboard/hooks/usePersistedValues';
import { approvalsRoutesConfig } from 'targets/web/modules/approvals/navigation/approvalsRoutesConfig';
import { useNetworkStatus } from 'targets/web/modules/dashboard/hooks/useNetworkStatus';
import { OnboardingAddAndConfirmModal } from 'targets/web/modules/jobs/components/OnboardingAddAndConfirmModal';
import {
  JobNameSelectModal,
  NamePreferenceType,
} from 'targets/web/modules/jobs/components/JobNameSelectModal';

const PERSIST_FORM_KEY = 'add-job-form-rev2';

export const NewJobView: FC = () => {
  const navigate = useNavigate();
  const t = useTranslationPrefix('jobs.jobs_view');
  const tBreadcrumbs = useTranslationPrefix('jobs.breadcrumbs');
  const { isOffline } = useNetworkStatus();
  const { hasRole } = useCaseCheckAccess();

  const { createJob } = useCaseCreateJob();

  const navigateBack = useCallback(() => navigate(-1), [navigate]);

  const location = useLocation() as Location<{ breadcrumbs: Breadcrumb[] } | null>;

  const { getPersistedValues, delPersistedValues } = usePersistedValues(PERSIST_FORM_KEY);
  const [effectiveInitialValues, setEffectiveInitialValues] = useState<JobDetailsData>();
  const [onboardingModalVisible, setOnboardingModalVisible] = useState(false);
  const [jobNameSelectModalVisible, setJobNameSelectModalVisible] = useState(false);
  const [values, setValues] = useState<JobDetailsData>();

  useEffect(() => {
    getPersistedValues().then((values) => {
      if (values && !effectiveInitialValues) {
        setEffectiveInitialValues(values);
      }
    });
  }, [effectiveInitialValues, getPersistedValues]);

  const defaultBreadcrumbs: Breadcrumb[] = useMemo(
    () => [
      {
        label: tBreadcrumbs('jobs_and_services'),
        url: jobsRoutes.jobs,
      },
      {
        label: tBreadcrumbs('job_details'),
        url: jobsRoutes.jobs,
      },
      {
        label: tBreadcrumbs('new_job'),
      },
    ],
    [tBreadcrumbs],
  );

  const handleSubmitThroughJobNameSelect = async (namePreference: NamePreferenceType) => {
    if (!values) {
      return;
    }
    await executeCreateJobFlow(mapToCreateJobData(values, namePreference));
  };

  const handleSubmit = useCallback(
    async (values: JobDetailsData) => {
      if (values.aircraft.code && values.aircraft.serialNumber) {
        setJobNameSelectModalVisible(true);
        setValues(values);
        return;
      }
      await executeCreateJobFlow(mapToCreateJobData(values));
    },
    [createJob, delPersistedValues, navigate, hasRole],
  );

  const mapToCreateJobData = (
    values: JobDetailsData,
    namePreference?: NamePreferenceType,
  ): CreateJobData => {
    return {
      notes: values.notes,
      stationId: values.station.id,
      customerId: values.customer.id,
      name:
        (namePreference === 'registration_number'
          ? values.aircraft.code
          : namePreference === 'serial_number'
          ? values.aircraft.serialNumber
          : values.aircraft.code || values.aircraft.serialNumber || '') ?? '',
      aircraftId: values.aircraft.id,
      due: values.deliverBy ? new Date(values.deliverBy) : new Date(),
      scheduledStart: values.scheduled ? new Date(values.scheduled) : new Date(),
      eta: values.eta ? new Date(values.eta) : new Date(),
      etd: values.etd ? new Date(values.etd) : new Date(),
      aircraftSerialNo: '',
      services: values.services.map((s) => ({
        serviceTypeId: s.serviceType.id,
        priceListId: s.priceList.id,
        quantity: s.quantity,
        estimatedTime: s.estimatedTime,
        price: s.price,
        discountPercentage: s.discountPercentage,
        notes: s.notes ?? '',
      })),
      poNumber: values.payment.poNumber,
      woNumber: values.payment.woNumber,
      squawkNumber: values.payment.squawkNumber,
      recurringType: values.recurringType,
      recurringStartsAt: values.recurringStartsAt,
      recurringEndsAt: values.recurringEndsAt,
      flatRate: values.flatRate,
    };
  };

  const executeCreateJobFlow = async (values: CreateJobData) => {
    await createJob(values);
    navigate(hasRole('show-job-approve-dashboard') ? approvalsRoutesConfig.main : jobsRoutes.jobs);
    delPersistedValues();
  };

  return (
    <>
      <Stack direction="row" width={1} justifyContent="flex-start" alignItems="center" gap={4}>
        <ConfirmationModal
          title={t('back_modal.title')}
          subtitle={t('back_modal.subtitle')}
          confirmText={t('back_modal.confirm')}
          cancelText={t('back_modal.cancel')}
          onConfirm={navigateBack}
          triggerButton={(onClick: () => void) => (
            <IconButton onClick={!isOffline ? onClick : navigateBack} color="primary">
              <ArrowBackIcon />
            </IconButton>
          )}
        />
        <Stack direction="row" alignItems="center" justifyContent="space-between" width={1}>
          <Stack direction="column" alignItems="flex-start" justifyContent="space-between">
            <Breadcrumbs items={location.state?.breadcrumbs ?? defaultBreadcrumbs} />

            <PageTitle title={t('add_title')} />
          </Stack>
          <Stack>
            <Button size="large" variant="outlined" onClick={() => setOnboardingModalVisible(true)}>
              <QuestionMarkIcon />
            </Button>
          </Stack>
        </Stack>
      </Stack>

      <JobDetailsForm
        stationEditable
        customerEditable
        aircraftEditable
        initialValues={effectiveInitialValues}
        onSubmit={handleSubmit}
        footer={
          <>
            {isOffline && <PersistFormikValues persistInvalid name={PERSIST_FORM_KEY} />}
            <FormikCancelSaveFooter onCancel={navigateBack} />
          </>
        }
      />

      <OnboardingAddAndConfirmModal
        open={onboardingModalVisible}
        onClose={() => setOnboardingModalVisible(false)}
      />

      <JobNameSelectModal
        open={jobNameSelectModalVisible}
        onClose={() => setJobNameSelectModalVisible(false)}
        onSubmit={handleSubmitThroughJobNameSelect}
      />
    </>
  );
};
