import { useCallback } from 'react';

import { JobSummary } from 'domain/entities/JobSummary';
import { BillingStatus } from 'domain/entities/BillingStatus';
import { JobStatus } from 'domain/entities/JobStatus';
import { useCaseUpdateJob } from 'application/jobs/useCases/useCaseUpdateJob';
import useSnackbar from 'targets/web/modules/dashboard/hooks/useSnackbar';

import { BillingStatusChip } from './BillingStatusChip';

export const JobBillingStatusChip = ({
  id,
  billingStatus,
  status,
}: Pick<JobSummary, 'id' | 'billingStatus' | 'status'>) => {
  const notify = useSnackbar();

  const { updateJobBillingStatus } = useCaseUpdateJob();

  const handleStatusChipChange = useCallback(
    async (status: BillingStatus) => {
      await updateJobBillingStatus(
        {
          jobId: id,
          billingStatus: status,
        },
        {
          onError: () => {
            notify("Error occurred while updating the Job's billing status", {
              variant: 'error',
            });
          },
        },
      );
    },
    [id, notify, updateJobBillingStatus],
  );

  return (
    <BillingStatusChip
      jobId={id}
      billingStatus={billingStatus}
      onSelect={handleStatusChipChange}
      editable={
        status !== JobStatus.Values.scheduled &&
        status !== JobStatus.Values.in_progress &&
        status !== JobStatus.Values.canceled
      }
    />
  );
};
