import { AuthContext } from 'domain/entities/AuthContext';
import { InjectableHook } from 'domain/types/InjectableHook';
import { Role } from 'domain/types/Roles';
import { ID } from 'domain/types/ID';
import { ResourceType } from 'application/auth/utils/resources';

export interface ResourcePermission {
  resource: `${ResourceType}:${ID}`;
  scope: string;
}

export const AccessProvider = Symbol('AccessProvider');
export type AccessProvider = InjectableHook<{
  hasRole(context: AuthContext, role: Role): boolean;
  hasPermission(context: AuthContext, permissions: ResourcePermission[]): Promise<boolean>;
}>;
