import { useCallback } from 'react';

import {
  AuthContextRepository,
  IAuthContextRepositoryHook,
} from 'domain/repositories/IAuthContextRepositoryHook';
import { Role } from 'domain/types/Roles';
import { AccessProvider, ResourcePermission } from 'application/auth/providers/IAccessProvider';
import { useHookInjection } from 'application/hooks';

export const useCaseCheckAccess = () => {
  const { hasRole, hasPermission } = useHookInjection<AccessProvider>(AccessProvider);
  const { useAuthContext } = useHookInjection<IAuthContextRepositoryHook>(AuthContextRepository);

  const authContext = useAuthContext();

  return {
    hasRole: (role: Role) => (authContext ? hasRole(authContext, role) : false),
    hasPermission: useCallback(
      async (permission: ResourcePermission[]) =>
        authContext ? hasPermission(authContext, permission) : false,
      [authContext, hasPermission],
    ),
  };
};
