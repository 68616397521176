import { useAuth } from 'react-oidc-context';
import { useCallback } from 'react';
import axios from 'axios';

import { AuthContext } from 'domain/entities/AuthContext';
import { Role } from 'domain/types/Roles';
import { AccessProvider, ResourcePermission } from 'application/auth/providers/IAccessProvider';

export const useOIDCAccessProvider: AccessProvider = () => {
  const auth = useAuth();

  const hasRole = useCallback((context: AuthContext, role: Role): boolean => {
    return context.roles.includes(role);
  }, []);

  const hasPermission = useCallback(
    async (context: AuthContext, permissions: ResourcePermission[]): Promise<boolean> => {
      const tokenEndpoint = auth.settings.metadata?.token_endpoint;
      if (!tokenEndpoint) {
        throw new Error('Token endpoint not found');
      }

      try {
        const params = new URLSearchParams();

        params.append('grant_type', 'urn:ietf:params:oauth:grant-type:uma-ticket');
        params.append('audience', 'prime-api');
        params.append('response_mode', 'decision');
        permissions.forEach((permission) => {
          params.append('permission', `${permission.resource}#${permission.scope}`);
        });

        const response = await axios.post<{ result: boolean }>(tokenEndpoint, params.toString(), {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            Authorization: 'Bearer ' + context.accessToken,
            Accept: 'application/json',
          },
          validateStatus: (status) => [200, 400, 403].includes(status),
        });

        return response.status === 200 && response.data.result;
      } catch (e) {
        console.error(e);
        throw new Error(`Unexpected response from Authorization Server: ${e}`);
      }
    },
    [auth.settings.metadata?.token_endpoint],
  );

  return {
    hasRole,
    hasPermission,
  };
};
