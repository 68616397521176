import { Form, Formik } from 'formik';
import { FC } from 'react';
import { Box, Checkbox, FormControlLabel, Stack, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { toFormikValidationSchema } from 'zod-formik-adapter';

import { useTranslationPrefix } from 'infrastructure/translations/i18n';
import { Button, TextField } from 'targets/web/components';
import { FormCustomerAutocomplete } from 'targets/web/modules/dashboard/components';
import { StationAutocomplete } from 'targets/web/modules/jobs/components/JobDetailsForm/StationAutocomplete';
import {
  PrimaryJobDetailsData,
  PrimaryJobDetailsSchema,
} from 'targets/web/modules/jobs/components/JobDetailsForm/PrimaryJobDetails';

export type AssociationFormData = {
  station?: PrimaryJobDetailsData['station'];
  customer?: PrimaryJobDetailsData['customer'];
};

export interface AssociationFormProps {
  disabled?: boolean;
  priceListName: string;
  initialValues: AssociationFormData;
  onSubmit: (data: AssociationFormData) => Promise<void>;
  onCancel: () => void;
  type?: 'add' | 'edit';
}

export const AssociationForm: FC<AssociationFormProps> = ({
  disabled,
  priceListName,
  initialValues,
  onSubmit,
  onCancel,
  type,
}) => {
  const t = useTranslationPrefix('configuration.price_list_details.association_form');

  const validationSchema = toFormikValidationSchema(
    PrimaryJobDetailsSchema.partial().refine((val) => val.station && val.customer, {
      message: t('customer_or_station_required'),
    }),
  );

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      enableReinitialize
      validationSchema={validationSchema}
    >
      {({ isSubmitting, values, setFieldValue }) => (
        <Form>
          <Stack gap={6} pt={6}>
            <Typography variant="h4">{t(type === 'edit' ? 'edit_title' : 'add_title')}</Typography>
            <Stack gap={4}>
              <TextField
                label={t('price_list_label')}
                readOnly
                disabled
                defaultValue={priceListName}
                size="small"
              />
              <FormCustomerAutocomplete disabled={disabled} customerEditable={!disabled} optional />
              <StationAutocomplete disabled={disabled} stationEditable={!disabled} optional />
            </Stack>
            <Stack direction="row" width={1} justifyContent="space-between" gap={2}>
              <Box>
                <FormControlLabel
                  label={t('assign_to_all_stations')}
                  control={
                    <Checkbox
                      color="primary"
                      defaultChecked={values.station === null}
                      value={values.station === null}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setFieldValue('station', event.target.checked ? null : undefined);
                        setFieldValue('customer', null);
                      }}
                    />
                  }
                />
              </Box>
              <Box>
                <Button
                  color="secondary"
                  variant="outlined"
                  size="medium"
                  disabled={disabled || isSubmitting}
                  onClick={onCancel}
                  sx={{ mr: 2 }}
                >
                  {t('cancel_button')}
                </Button>
                <LoadingButton
                  loading={isSubmitting}
                  disabled={disabled}
                  size="medium"
                  variant="contained"
                  type="submit"
                >
                  {t(type === 'edit' ? 'edit_button' : 'create_button')}
                </LoadingButton>
              </Box>
            </Stack>
          </Stack>
        </Form>
      )}
    </Formik>
  );
};

export default AssociationForm;
