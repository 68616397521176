export const ShowAllJobs = 'job-show-all';
export const ShowAllJobRequests = 'job-requests-show-all';
export const ReviewJobRequest = 'job-requests-review';
export const ViewAllServiceTypes = 'service-types-view';
export const ViewApprovalsDashboard = 'show-job-approve-dashboard';
export const ViewBillingDashboard = 'show-billing-dashboard';
export const ViewCustomersDashboard = 'customers-view';
export const EditCustomer = 'edit-customers';
export const CreateCustomer = 'create-customers';
export const ShowJobActions = 'show-job-actions';
export const ShowConfiguration = 'show-configuration';
export const ShowOperations = 'show-operations';
export const ManageAssociations = 'manage-associations';
export const CreateJobWithBilling = 'job-create-with-billing';
export const ConfirmBilling = 'confirm-billing';
export const ViewPriceLists = 'price-lists-view';

export type Role =
  | typeof ShowAllJobs
  | typeof ShowAllJobRequests
  | typeof ViewApprovalsDashboard
  | typeof ReviewJobRequest
  | typeof ViewAllServiceTypes
  | typeof ViewBillingDashboard
  | typeof ViewCustomersDashboard
  | typeof EditCustomer
  | typeof ShowJobActions
  | typeof CreateCustomer
  | typeof ShowConfiguration
  | typeof ShowOperations
  | typeof ManageAssociations
  | typeof CreateJobWithBilling
  | typeof ConfirmBilling
  | typeof ViewPriceLists;
