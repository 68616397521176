import { useCallback } from 'react';

import { JobSummary } from 'domain/entities/JobSummary';
import { isEntityLocked } from 'domain/entities/BillingStatus';
import { useCaseUpdateJob } from 'application/jobs/useCases/useCaseUpdateJob';
import usePermission from 'application/auth/hooks/usePermission';
import { JobResourceType } from 'application/auth/utils/resources';
import { useCaseCompleteJob } from 'application/jobs/useCases/useCaseCompleteJob';
import { useTranslationPrefix } from 'infrastructure/translations/i18n';
import useSnackbar from 'targets/web/modules/dashboard/hooks/useSnackbar';

import { StatusChip } from './StatusChip';

export const JobStatusChip = ({
  id,
  status,
  billingStatus,
  due,
}: Pick<JobSummary, 'id' | 'status' | 'due' | 'billingStatus'>) => {
  const t = useTranslationPrefix('components.cards');
  const notify = useSnackbar();

  const { updateJob } = useCaseUpdateJob();
  const { completeJob } = useCaseCompleteJob();
  const editable = !isEntityLocked({ billingStatus, status });

  const { granted: editGranted } = usePermission(
    editable
      ? {
          resource: `${JobResourceType}:${id}`,
          scope: 'update-statuses',
        }
      : undefined,
  );

  const handleStatusChipChange = useCallback(
    async (status: 'submitted' | 'in_progress' | 'completed') => {
      if (status === 'completed') {
        completeJob({
          jobId: id,
        })
          .then(() => {
            notify(t('complete_success'), {
              variant: 'success',
            });
          })
          .catch((error) => {
            const errorCode = error.body?.message || error.message || 'unknown';
            const message = ['worklog_not_finished', 'services_unfinished'].includes(errorCode)
              ? t(`complete_errors.${errorCode}`)
              : errorCode;
            notify(t('complete_error', { error: message }), {
              variant: 'error',
            });
          });
        return;
      }

      await updateJob(
        {
          jobId: id,
          data: {
            status: status === 'submitted' ? 'scheduled' : status,
          },
        },
        {
          onError: () => {
            notify("Error occurred while updating the Job's status", {
              variant: 'error',
            });
          },
        },
      );
    },
    [id, notify, updateJob, completeJob, t],
  );

  return (
    <StatusChip
      status={status}
      dueDate={due}
      editable={editable && editGranted}
      availableStatusChanges={['scheduled', 'in_progress', 'completed']}
      onSelect={handleStatusChipChange}
    />
  );
};
