import { QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { FC, PropsWithChildren } from 'react';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { enqueueSnackbar } from 'notistack';
import * as Sentry from '@sentry/react';
import { ErrorHttpStatusCode } from '@ts-rest/core';

export interface TsRestErrorResponse<ErrorBody extends { message: string } = { message: string }> {
  status: ErrorHttpStatusCode;
  body: ErrorBody;
}

declare module '@tanstack/react-query' {
  interface Register {
    defaultError: Error | TsRestErrorResponse;
  }
}

const globalForbiddenErrorHandler = (error: Error | TsRestErrorResponse) => {
  if ('status' in error && [401, 403, 404].includes(error.status)) {
    if (error.status === 403) {
      enqueueSnackbar(
        `Missing Permission | ${
          typeof error.body === 'object' && error.body && 'message' in error.body
            ? error.body?.message
            : ''
        }`,
        {
          variant: 'error',
          preventDuplicate: true,
        },
      );
    }

    // Do nothing for 401 & 404
    return;
  }

  Sentry.captureException(error instanceof Error ? error : JSON.stringify(error));
};

const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: (error, query) => {
      if (query.meta?.silentError) {
        return;
      }

      return globalForbiddenErrorHandler(error);
    },
  }),
  defaultOptions: {
    queries: {
      gcTime: 1000 * 60 * 15, // 15 mins
      networkMode: 'offlineFirst',
      // we hand over this to the useOfflineModeTokenRefresh in order to wait for token refresh
      refetchOnReconnect: false,
      refetchOnWindowFocus: !import.meta.env.DEV,
      retry: (failureCount, error) => {
        if ('status' in error && [400, 401, 403, 404].includes(error.status)) {
          return false;
        }
        return failureCount < 3;
      },
    },
    mutations: {
      onError: globalForbiddenErrorHandler,
      networkMode: 'offlineFirst',
    },
  },
});

const InfrastructureProvider: FC<PropsWithChildren> = ({ children }) => {
  return (
    <QueryClientProvider client={queryClient}>
      {children}

      <ReactQueryDevtools initialIsOpen={false} buttonPosition="bottom-right" />
    </QueryClientProvider>
  );
};

export default InfrastructureProvider;
