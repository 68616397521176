import { useQuery } from '@tanstack/react-query';

import { ResourcePermission } from 'application/auth/providers/IAccessProvider';
import { useCaseCheckAccess } from 'application/auth/useCases/useCaseCheckAccess';

const usePermission = (permission?: ResourcePermission) => {
  const { hasPermission } = useCaseCheckAccess();

  const { data, isLoading, error } = useQuery({
    enabled: !!permission,
    queryKey: ['PERMISSION', permission?.resource, permission?.scope] as const,
    queryFn: async ({ queryKey }) => {
      return hasPermission([
        {
          resource: queryKey[1] as ResourcePermission['resource'],
          scope: queryKey[2] as string,
        },
      ]);
    },
  });

  return {
    granted: !isLoading && !error ? data : false,
    isLoading,
  };
};

export default usePermission;
