import { useCallback } from 'react';
import { Job } from 'api/domain/entities/Job';
import { useNavigate } from 'react-router-dom';
import { ExcludedServiceCodesFromPostWorkQuestionnaire } from 'common/data/service';

import { Service } from 'domain/entities/Service';
import { isEntityLocked } from 'domain/entities/BillingStatus';
import { ServiceStatus } from 'domain/entities/ServiceStatus';
import { ID } from 'domain/types/ID';
import { useCaseUpdateService } from 'application/services/useCases/useCaseUpdateService';
import usePermission from 'application/auth/hooks/usePermission';
import { useCaseCompleteService } from 'application/services/useCases/useCaseCompleteService';
import { JobResourceType } from 'application/auth/utils/resources';
import useSnackbar from 'targets/web/modules/dashboard/hooks/useSnackbar';

import { StatusChip } from './StatusChip';

type ServiceStatusChipProps = Pick<
  Service,
  'id' | 'status' | 'scheduledDue' | 'billingStatus' | 'jobId' | 'code'
> & {
  jobBillingStatus: Job['billingStatus'];
  availableStatusChanges: ServiceStatus[];
  onChange?: (status: 'submitted' | 'in_progress' | 'completed') => void;
};

export const ServiceStatusChip = ({
  id,
  jobId,
  status,
  scheduledDue,
  billingStatus,
  jobBillingStatus,
  onChange,
  availableStatusChanges,
  code,
}: ServiceStatusChipProps) => {
  const notify = useSnackbar();
  const navigate = useNavigate();

  const { updateService } = useCaseUpdateService();
  const { completeService, isPending } = useCaseCompleteService();
  const editable =
    !isEntityLocked({ billingStatus, status }) &&
    !isEntityLocked({ billingStatus: jobBillingStatus, status });

  const { granted: editGranted } = usePermission(
    editable
      ? {
          resource: `${JobResourceType}:${jobId}`,
          scope: 'update-statuses',
        }
      : undefined,
  );

  const handleStatusChipChange = useCallback(
    async (status: 'submitted' | 'in_progress' | 'completed') => {
      if (status === 'completed') {
        if (ExcludedServiceCodesFromPostWorkQuestionnaire.includes(code)) {
          completeService({
            serviceId: ID(id),
          });
        } else {
          navigate(`/jobs/${jobId}/services/${id}/post-work-questionnaire`);
        }
      } else {
        await updateService(
          {
            serviceId: id,
            data: {
              status: status === 'submitted' ? 'pending' : status,
            },
          },
          {
            onError: () => {
              notify("Error occurred while updating the Service's status", {
                variant: 'error',
              });
            },
          },
        );
      }

      onChange?.(status);
    },
    [onChange, id, notify, updateService, jobId, navigate, code, completeService],
  );

  return (
    <StatusChip
      status={status}
      dueDate={scheduledDue}
      editable={editable && editGranted && !isPending}
      availableStatusChanges={availableStatusChanges}
      onSelect={handleStatusChipChange}
    />
  );
};
