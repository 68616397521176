import { FC, useCallback, useMemo, useState } from 'react';
import { Stack, Theme, useMediaQuery } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import { useNavigate } from 'react-router';
import { SortingDirection } from 'common/types/SortingOptions';

import { ShowJobActions } from 'domain/types/Roles';
import { useCaseSearchJobs } from 'application/jobs/useCases/useCaseSearchJobs';
import { useCaseSearchServices } from 'application/services/useCases/useCaseSearchServices';
import { useCaseGetJobsSummary } from 'application/jobs/useCases/useCaseGetCountJobsSummary';
import { useCaseCheckAccess } from 'application/auth/useCases/useCaseCheckAccess';
import { usePrefetchJobs } from 'application/jobs/useCases/useCaseGetJobDetails';
import { usePrefetchServices } from 'application/services/useCases/useCaseGetService';
import { useTranslationPrefix } from 'infrastructure/translations/i18n';
import { Button, Loader, VirtualizedGrid } from 'targets/web/components';
import {
  JobLogCard,
  JobsTable,
  JobViewTabs,
  ServiceLogCard,
  ServicesTable,
} from 'targets/web/modules/jobs/components';
import { jobsRoutes } from 'targets/web/modules/jobs/navigation/jobsRoutes';
import { useJobViewFilters } from 'targets/web/modules/jobs/hooks/useJobViewFilters';
import { FiltersContainer, PageTitle } from 'targets/web/modules/dashboard/components';
import Summary from 'targets/web/modules/jobs/components/Summary';
import { useNetworkStatus } from 'targets/web/modules/dashboard/hooks/useNetworkStatus';
import { OfflinePlaceholder } from 'targets/web/modules/dashboard/components/OfflinePlaceholder/OfflinePlaceholder';
import { OnboardingJobAndServicesModal } from 'targets/web/modules/jobs/components/OnboardingJobAndServicesModal';

export const JobsView: FC = () => {
  const navigate = useNavigate();
  const t = useTranslationPrefix('jobs.jobs_view');
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const { hasRole } = useCaseCheckAccess();
  const { isOffline } = useNetworkStatus();

  const { jobFilters, serviceFilters, filters, setFilters, viewType, logType } =
    useJobViewFilters();
  const { data: summaryData } = useCaseGetJobsSummary(jobFilters);

  const {
    allPages: jobs,
    fetchNextPage: fetchNextJobsPage,
    hasNextPage: hasNextJobsPage,
    isFetching: isFetchingJobs,
  } = useCaseSearchJobs(
    logType === 'job-log'
      ? {
          ...jobFilters,
          sortBy: 'status',
          direction: SortingDirection.Ascending,
        }
      : null,
  );
  const {
    allPages: services,
    fetchNextPage: fetchNextServicesPage,
    hasNextPage: hasNextServicesPage,
    isFetching: isFetchingServices,
    refetch,
  } = useCaseSearchServices(
    logType === 'service-log'
      ? {
          ...serviceFilters,
          customerIds: serviceFilters.customerId ? [serviceFilters.customerId] : undefined,
          sortBy: 'status',
          direction: SortingDirection.Ascending,
        }
      : null,
  );

  const jobsIds = useMemo(() => jobs.map(({ id }) => id), [jobs]);
  const serviceIds = useMemo(() => services.map(({ id }) => id), [services]);
  usePrefetchJobs(jobsIds);
  usePrefetchServices(serviceIds);

  const loadMoreJobs = useCallback(() => {
    if (hasNextJobsPage && !isFetchingJobs) {
      fetchNextJobsPage();
    }
  }, [fetchNextJobsPage, hasNextJobsPage, isFetchingJobs]);

  const loadMoreServices = useCallback(() => {
    if (hasNextServicesPage && !isFetchingServices) {
      fetchNextServicesPage();
    }
  }, [fetchNextServicesPage, hasNextServicesPage, isFetchingServices]);

  const virtualizedGridProps = useMemo(
    () => ({
      useWindowScroll: true,
      spacing: 4,
      minColumnWidth: logType === 'job-log' ? 280 : isMobile ? 400 : 800,
      endReached: logType === 'job-log' ? loadMoreJobs : loadMoreServices,
      overscan: 500,
    }),
    [isMobile, loadMoreJobs, loadMoreServices, logType],
  );

  const [onboardingModalVisible, setOnboardingModalVisible] = useState(false);

  const showSpinner = (isFetchingJobs && !jobs.length) || (isFetchingServices && !services.length);

  const shouldRenderOfflineElement =
    isOffline &&
    ((logType === 'job-log' && !jobs.length && !isFetchingJobs) ||
      (logType === 'service-log' && !services.length && !isFetchingServices));

  return (
    <>
      <Stack width={1} direction="row" alignItems="center" justifyContent="space-between">
        <PageTitle title={t('title')} />
        <Stack direction="row" spacing={4}>
          <Button size="large" variant="outlined" onClick={() => setOnboardingModalVisible(true)}>
            <QuestionMarkIcon />
          </Button>
          {hasRole(ShowJobActions) && (
            <Button
              size="large"
              startIcon={<AddIcon />}
              onClick={() => navigate(jobsRoutes.internalRoutes.new)}
            >
              {t('add_job_button')}
            </Button>
          )}
        </Stack>
      </Stack>
      <Summary items={summaryData} />

      <FiltersContainer
        initialValues={filters}
        onFiltersChange={(values) => setFilters((current) => ({ ...current, ...values }))}
      />

      <Stack
        gap={4}
        sx={{
          position: 'relative',
          width: 1,
          height: 1,
        }}
      >
        <JobViewTabs />

        {shouldRenderOfflineElement && <OfflinePlaceholder />}

        {showSpinner && viewType === 'grid-view' ? (
          <Stack height={1} width={1} alignItems="center" justifyContent="center">
            <Loader size={48} />
          </Stack>
        ) : viewType === 'grid-view' ? (
          logType === 'job-log' ? (
            <VirtualizedGrid
              {...virtualizedGridProps}
              data={jobs}
              computeItemKey={(_index, element) => element.id}
              itemContent={(_index, element) => (
                <JobLogCard {...element} onClick={() => navigate(`/jobs/${element.id}/summary`)} />
              )}
            />
          ) : (
            <VirtualizedGrid
              {...virtualizedGridProps}
              computeItemKey={(_index, element) => element.id}
              data={services}
              itemContent={(_index, element) => (
                <ServiceLogCard
                  {...element}
                  jobBillingStatus={element.job.billingStatus}
                  refetch={refetch}
                />
              )}
            />
          )
        ) : (
          <Stack sx={{ maxHeight: 'calc(100vh - 168px)' }}>
            {!shouldRenderOfflineElement ? (
              logType === 'job-log' ? (
                <JobsTable />
              ) : (
                <ServicesTable />
              )
            ) : null}
          </Stack>
        )}
      </Stack>

      <OnboardingJobAndServicesModal
        open={onboardingModalVisible}
        onClose={() => setOnboardingModalVisible(false)}
      />
    </>
  );
};
